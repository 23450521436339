.SignIn {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Notification {
    width: 40%;
    margin-top: 30px;
    box-sizing: border-box;
}

.Notification.Error p{
    background-color: rgba(255,0,15,0.24);
    border-color: #D70000;
}
.Notification p{
    box-sizing: border-box;
    width: 100%;
    padding: 20px 50px;
    background-color: #F9F3EE;
    border: solid 2px #D9A963;
}
.Form {
    display: flex;
    flex-direction: column;
}

.Form{
    background-color: #F9F3EE;
    width: 30%;
    margin: auto;
    padding: 50px;
    align-items: center;
    margin-block: 50px;

}
.Form, .Form label {
    display: flex;
    flex-direction: column;
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    line-height: 24px;

}

.Form input[type="file"]{
    display: none;
}

.Form img {
    width: 83px;
    height: 83px;
    object-fit: cover;
    object-position: center;
}
.Form label {
    width: 100%;
}
.Form label p {
    margin-bottom: 15px;
}

.Form input {
    height: 64px;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 35px;

}
.Submit {
    display: flex;
    align-items: center;
}

.Submit span {
    margin-inline: 20px;
}

@media all and (max-width: 1300px) {
    .Form {
        width: 70%;
    }
}

@media all and (max-width: 500px) {
    .Submit {
        flex-direction: column;
    }
    .Submit span {
        margin-block: 20px;
    }
}