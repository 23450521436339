.Book {
    padding-block: 5em;
    display: flex;
    align-items: stretch;

}
.BookContainer {
    background-color: #FFFFFF;
    margin: auto;
    border-radius: 7px;
    box-shadow:  0 4px 34px rgba(0, 0, 0, 0.07);
    box-sizing: border-box;
}

.BookContainer hr {
    width: 60%;
    border-color: transparent;
    background-color: #F2E3CE;
    height: 1px;
}
.Deleted {
    font-family: "Libre Baskerville", serif;
    padding-block: 5em;
    background-color: #FFFFFF;
    margin: auto;
    border-radius: 7px;
    box-shadow:  0 4px 34px rgba(0, 0, 0, 0.07);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;

}

.Deleted h1 {

    font-size: 44px;
    font-style: italic;
    color: #796157;
    padding-bottom: 15px;
}
.Deleted p {
    font-size: 16px;
    margin-bottom: 20px;
}

.Deleted img {
    margin-bottom: 75px;
}
.BookImage {
    width: calc(40% - 80px);
    background-position: center;
    background-size: cover;
    margin-right: 80px;
    margin-left: 120px;
}
.Book img {
    width: 40%;
    padding-right: 80px;
    padding-left: 120px;
}
.BookContent {
    width: 60%;
    display: flex;
    flex-direction: column;
}

.Owner {

}
.BookInfo {
    display: grid;
    width: 100%;
    max-width: 465px;
    grid-template-rows: 125px 65px 65px;
    grid-template-columns: 1fr 1fr 1fr;
    box-sizing: border-box;
    gap: 1px;
}
.BookInfo > * {
    outline: 1px solid #000;
    display: flex;
    align-items: center;
}
.BookInfo h1 {
    grid-column-start: 1;
    grid-column-end: 4;
    font-size: 28px;
    padding-left: 20px;
}

.Author {
    grid-column-start: 1;
    grid-column-end: 3;
    padding-left: 20px;
}

.PublishDate, .Genre, .Rating {
    justify-content: center;
}

.Rating {
    grid-column-start: 2;
    grid-column-end: 4;
}
.Rating div {
    margin-right: 20px;
}

.Book form {
    position: relative;
    overflow: hidden;
    background-color: #F9F3EE;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-block: 32px;
    margin-top: 50px;
    max-width: 465px
}

.Book form p {
    font-family: "Libre Baskerville", serif;
    font-size: 18px;
    margin-bottom: 22px;
}

.Book form input[type="radio"] {
    position: absolute;
    left: -10000px
}
.Book form input[type="submit"] {
    padding: 20px 23px;
    border: none;
    background-color: #D9A963;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
}
.Stars, .Stars label{
    display: flex;
    margin-bottom: 22px;
}
.Owner {
    color: #2C1810;
    font-family: "DM Sans", sans-serif;
    padding: 20px;
}

.Owner p {
    margin-bottom: 20px;
}

.Owner a {
    color: #929292;
    margin-right: 20px;
}

.Owner span {
    color: #D70000;
    cursor: pointer;
    text-decoration: underline;
}
@media all and (max-width: 1080px) {
    .Book {
        flex-direction: column;
        align-items: center;
    }
    .BookImage {
        height: 300px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        margin-inline: 0;
    }
    .BookContent {
        align-items: center;
        margin-top: 2em;
    }

}

@media all and (max-width: 600px) {
    .BookContent {
        width: 90%;
    }

}