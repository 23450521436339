/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F2E3CE;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root > div {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  width: 100%;
  max-width: 1440px;
  margin:auto;

}

.content-container {
  width: 90%;
  max-width: 1320px;
  margin: auto;
  padding-block: 5em;

}

.backArrow {
  display: block;
  margin-bottom: 5em;
  color: #796157;
  font-family: "DM Sans", sans-serif;

  text-decoration: none;
}

h1 {
  font-family: "Libre Baskerville", serif;
  font-size: 44px;
  font-style: italic;
  color: #796157;
}

button, .button {
  color: #2C1810;
  background-color: #D9A963;
  text-decoration: none;
  padding: 20px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  transition: 0.5s;
}

button:hover, .button:hover {
  text-decoration: none;
  background-color: #B99056;
  color: #fff;
  transition: 0.5s;
}